import React, { Fragment } from "react";
import { useForm } from "react-hook-form";
import updateAction from "./updateAction";
import { Input, Error } from "../../../../components/ui/form";
import Button from "../../../../components/ui/button";
import { Title ,NextButton} from "./free-consultation-steps.style";
import { Col } from "../../../../components/ui/wrapper";
import { useStateMachine } from "little-state-machine";
import { ErrorStepSection } from './ErrorStepsSection';

const StepNine = ({ count, setCount }) => {
    const { register, handleSubmit, formState: { errors } } = useForm({
      mode: "onBlur",
    });
    const { actions, state } = useStateMachine({updateAction});
  
    const onSubmit = (data) => {
      actions.updateAction(data);
      setCount(count + 1);
    };
  
    const onPreviousState = () => {
      setCount(count - 1);
    };
  
    return (
      <Fragment>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Title>What is your email ?</Title>
  
          <Input
            {...register('email', { required: "Email is required" })}
            placeholder="Your email"
            type="email"
            defaultValue={state.data.email} />
          <ErrorStepSection 
            field="email" 
            errors={errors} 
            errorMessage={errors?.email?.message}
            stepNumber={8}
          />              
          <Col lg={12}>
            <Button 
              mt="30px" 
              skin="dark"
              onClick={(e) => onPreviousState()}
            >
              Back
            </Button>
            <NextButton hover={3} skin='secondary'>
              Next
            </NextButton>
          </Col>
        </form>
      </Fragment>
    );
  };

  export {StepNine}