import styled from "styled-components";
import { device } from "../../theme";
import Heading from "../../components/ui/heading";
import Text from "../../components/ui/text";

export const SectionWrap = styled.section`
  padding-top: 50px;
  padding-bottom: 50px;
  @media ${device.medium} {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  @media ${device.small} {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .box-item {
    margin-top: 60px;
    @media ${device.small} {
      margin-top: 47px;
    }
  }
`;

export const SectionBottom = styled.div`
  margin-top: 60px;
  text-align: center;
  @media ${device.small} {
    margin-top: 45px;
  }
`;

export const FreeConsultationHeading = styled(Heading)`
  font-size: 26px;
  line-height: 1.5;
  margin-bottom: 0;
  a {
    color: inherit;
    font-size: inherit;
  }
  span {
    color: ${(props) => props.theme.colors.themeColor};
  }
`;

export const FreeConsultationTagline = styled(Text)`
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 0;
  letter-spacing: 2px;
  a {
    color: inherit;
    font-size: inherit;
  }
  span {
    color: ${(props) => props.theme.colors.themeColor};
  }
`;

export const FreeConsultationText = styled(Text)`
  font-size: 18px;
  line-height: 1.5;
  margin-top: 10px;
  margin-bottom: 10px;
  a {
    color: inherit;
    font-size: inherit;
  }
  span {
    color: ${(props) => props.theme.colors.themeColor};
  }
`;

export const ItemsUl = styled.ul`
  margin-top: 30px;
  position: relative;
  li:after {
    content: "";
    position: absolute;
    top: 15px;
    font-size: 30px;
    width: 15px;
    height: 3px;
    background: ${(props) => props.theme.colors.themeColor};
    left: 0;
    color: #fff;
  }
`;

export const ItemsLi = styled.li`
  display: list-item;
  list-style: none;
  position: relative;
  padding-left: 35px;
  line-height: 2;
  margin-bottom: 5px;
  font-size: 16px;
`;
