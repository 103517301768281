import React, { Fragment } from "react";
import { useForm } from "react-hook-form";
import updateAction from "./updateAction";
import { Input } from "../../../../components/ui/form";
import Button from "../../../../components/ui/button";
import { Title, NextButton } from "./free-consultation-steps.style";
import { Col } from "../../../../components/ui/wrapper";
import { useStateMachine } from "little-state-machine";
import { ErrorStepSection } from './ErrorStepsSection';

const StepFour = ({ count, setCount }) => {
    const { register, handleSubmit, formState: { errors } } = useForm({
      mode: "onBlur",
    });
    const { actions, state } = useStateMachine({updateAction});
  
    const onSubmit = (data) => {
      actions.updateAction(data);
      setCount(count + 1);
    };
  
    const onPreviousState = () => {
      setCount(count - 2);
    };
  
    return (
      <Fragment>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Title>Please tell us your company name?</Title>
          <Input
            style={{ margin: "0px 25px 0px 5px" }}
            type="text"
            {...register('companyName', { required: "Company name is required" })}
            id="companyName"
            placeholder="Company Name *"
            defaultValue={state.data.companyName} />
          <ErrorStepSection 
            field="companyName" 
            errors={errors} 
            errorMessage={errors?.companyName?.message}
            stepNumber={3}
          />  
          <Col lg={12}>
            <Button 
              mt="30px" 
              onClick={(e) => onPreviousState()}
              skin="dark"
            >
              Back
            </Button>
            <NextButton hover={3} skin='secondary'>
              Next
            </NextButton>
          </Col>
        </form>
      </Fragment>
    );
  };

  export {StepFour}