import React, { useState } from "react";
import { createStore } from "little-state-machine";

import {
    StepOne, 
    StepTwo, 
    StepFour, 
    StepFive, 
    StepSix,
    StepSeven,
    StepEight,
    StepNine,
    StepTen,
    StepEleven
} from './steps';

const StepsManager = (props) => {
    const [count, setCount] = useState(0);
    createStore({
        data: {},
    });

    if (count == 0) {
        return <StepOne count={count} setCount={setCount} />;
      } else if (count === 1) {
        return <StepTwo count={count} setCount={setCount} />;
      } else if (count === 2) {
        return <StepFour count={count} setCount={setCount} />;
        /* return <StepThree count={count} setCount={setCount} />; */
      } else if (count === 3) {
        return <StepFive count={count} setCount={setCount} />;
      } else if (count === 4) {
        return <StepSix count={count} setCount={setCount} />;
      } else if (count === 5) {
        return <StepSeven count={count} setCount={setCount} />;
      } else if (count === 6) {
        return <StepEight count={count} setCount={setCount} />;
      } else if (count === 7) {
        return <StepNine count={count} setCount={setCount} />;
      } else if (count === 8) {
        return <StepTen count={count} setCount={setCount} />;
      } else if (count === 9) {
        return <StepEleven count={count} setCount={setCount} />;
      }
}

export {StepsManager}