import React, { Fragment } from "react";
import { useForm } from "react-hook-form";
import updateAction from "./updateAction";
import { Input } from "../../../../components/ui/form";
import Button from "../../../../components/ui/button";
import { Title } from "./free-consultation-steps.style";
import { Col } from "../../../../components/ui/wrapper";
import { useStateMachine } from "little-state-machine";
import { ErrorStepSection } from './ErrorStepsSection';

const StepOne = ({ count, setCount }) => {
  const { register, handleSubmit, formState: { errors }, reset } = useForm();
  const { actions, state } = useStateMachine({updateAction});

  const onSubmit = (data, e) => {
    actions.updateAction(data);
    setCount(count + 1);
  };

  return (
    <Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Title>What's your name?</Title>
        <Input
          type="text"
          {...register('name', { required: true })}
          id="name"
          defaultValue={state.data.name}
          placeholder="Name *" />
        <ErrorStepSection 
          field="name" 
          errors={errors} 
          errorMessage="Name is required"
          stepNumber={1}
        />
        {/* <Row>
          <Col lg={8}>
            <Error>{errors['name'] && "Name is required"}</Error>
            </Col>
          <Col lg={4} style={{
            fontSize: '10px',
            fontStyle: 'italic',
            fontWeight: 600,
            alignSelf: 'center'
          }}>( Step 1/12 )</Col>
        </Row> */}
        <Col lg={12}>
          <Button mt="30px" type="submit" hover={3} skin='secondary'>
            Next
          </Button>
        </Col>
 
      </form>
    </Fragment>
  );
};

export { StepOne };
