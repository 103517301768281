import React, { Fragment } from "react";
import { useForm } from "react-hook-form";
import updateAction from "./updateAction";
import { Error } from "../../../ui/form";
import Button from "../../../ui/button";
import Checkbox from "../../../ui/form";
import { Col, Row } from "../../../ui/wrapper";
import { Title, Rowwrap,ColWrap,NextButton } from "./free-consultation-steps.style";
import { useStateMachine } from "little-state-machine";
import { ErrorWrap } from "../../../ui/form/error/error.style";
import { ErrorStepSection } from './ErrorStepsSection';

const StepFive = ({ count, setCount }) => {
  const { register, handleSubmit, reset, formState: { errors }, setError, clearErrors } = useForm();
  const { actions } = useStateMachine({updateAction});

  const onSubmit = async data => {
    if (
      data.webApp === false &&
      data.mobileApp == false &&
      data.devOpsCloud == false
    ) {
      reset();
      setError("service", {
        type: "notMatch", 
        message: "Please choose at least one service"
      });
    } else {
      clearErrors("service");
      actions.updateAction(data);
      setCount(count + 1);
    }
  };

  const onPreviousState = () => {
    setCount(count - 1);
  };

  return (
    <Fragment>
      <Col lg={12}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Title>What kind of services would you need?</Title>
          <ColWrap>
            <Rowwrap>
              <input
                style={{ margin: "0px 25px 0px 5px" }}
                type="checkbox"
                {...register('webApp')}
                id="webApp" />
              <label htmlFor="range">Web App</label>{" "}
            </Rowwrap>
            <Rowwrap>
              <input
                style={{ margin: "0px 25px 0px 5px" }}
                type="checkbox"
                {...register('mobileApp')}
                id="mobileApp" />
              <label htmlFor="range">Mobile App </label>{" "}
            </Rowwrap>
            <Rowwrap>
              <input
                style={{ margin: "0px 25px 0px 5px" }}
                type="checkbox"
                {...register('devOpsCloud')}
                id="devOpsCloud" />
              <label htmlFor="range">Dev Ops & Cloud</label>{" "}
            </Rowwrap>
          </ColWrap>
          <ErrorStepSection 
            field="service" 
            errors={errors} 
            errorMessage={errors?.service?.message}
            stepNumber={4}
          />  

          <Col lg={12}>
            <Button 
              mt="30px" 
              skin="dark"
              onClick={e => onPreviousState()}
            >
              Back
            </Button>
            <NextButton hover={3} skin='secondary'>
              Next
            </NextButton>
          </Col>
        </form>
      </Col>
    </Fragment>
  );
};

export { StepFive };
