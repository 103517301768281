import React, { Fragment } from "react";
import { useForm } from "react-hook-form";
import updateAction from "./updateAction";
import { Error } from "../../../../components/ui/form";
import Button from "../../../../components/ui/button";
import { Title } from "./free-consultation-steps.style";
import { Col } from "../../../../components/ui/wrapper";
import { useStateMachine } from "little-state-machine";

const StepEleven = ({ count, setCount }) => {
  const { handleSubmit, reset } = useForm({ mode: "onChange" });
  const { actions, state } = useStateMachine({updateAction});

  const onSubmit = (data, e) => {
    setCount(count * 0);
    actions.updateAction(data);
  };

  return (
    <Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Title>
          Thank you! We have received these details. 
          <br/>We will revert in the next 1 business day.
        </Title>
        <Col lg={12}>
          <Button 
            mt="30px" 
            hover={3}
            to={"/"}
          >
            Ok, Go Back
          </Button>
        </Col>
      </form>
    </Fragment>
  );
};

export { StepEleven };
