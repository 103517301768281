import styled, { css } from "styled-components";
import { device } from "../../../../theme";
import Button from "../../../ui/button";

export const FirstPageFormWrapper = styled.section`
  padding-top: 100px;
  padding-bottom: 64px;
  @media ${device.medium} {
    padding-top: 15px;
    padding-bottom: 45px;
  }
  @media ${device.small} {
    padding-top: 10px;
    padding-bottom: 45px;
  }
`;

export const LeftBox = styled.div`
  @media ${device.medium} {
    margin-bottom: 45px;
  }
  text-align: center;
  justify-content: center;
  display: flex;
`;

export const Title = styled.h5`
  font-weight: 400;
  line-height: 1.3;
  color: rgb(68, 68, 67);
  margin-bottom: 16px;
  font-size: 2.25rem;
`;

export const Row = styled.div`
  align-items: center;
  justify-content: center;
  text-align: center;
  display: table;
`;

export const Rowwrap = styled.div`
  align-items: center;
  justify-content: center;
  text-align: center;
  display: table;
  @media ${device.xxsmall} {
    display: flex;
    justify-content: start;
  }
`;
export const ColWrap = styled.div`
  @media ${device.medium} {
    padding-left: 20%;
  }
  @media ${device.xxsmall} {
    padding-left: 0px;
  }
`;

export const ErrorWrap = styled.p`
  color: red;
`;

export const Textarea = styled.textarea`
    width: 100%;
    min-height: ${(props) => props.height || "150px"};
    color: ${(props) => props.color || props.theme.colors.textColor} !important;
    background-color: ${(props) => props.bgcolor || "#f8f8f8"};
    font-size: ${(props) => props.fontsize || "15px"};
    font-weight: ${(props) => props.fontweight || 400};
    border-width: ${(props) => props.borderwidth || "1px"};
    border-color: ${(props) => props.bordercolor || "#f8f8f8"};
    border-style: solid;
    border-top-color: ${(props) => props.bordertopcolor};
    border-right-color: ${(props) => props.borderrightcolor};
    border-bottom-color: ${(props) => props.borderbottomcolor};
    border-left-color: ${(props) => props.borderleftcolor};
    border-radius: ${(props) => props.borderradius || "5px"};
    padding-left: ${(props) => props.pl || "20px"};
    padding-right: ${(props) => props.pr || "20px"};
    padding-top: ${(props) => props.pt || "15px"};
    padding-bottom: ${(props) => props.pb || "15px"};
    &::placeholder,
    &::-webkit-input-placeholder {
        color: ${(props) => props.theme.colors.textColor} !important;
    }
    &:-ms-input-placeholder {
        color: ${(props) => props.theme.colors.textColor} !important;
    }
    ${(props) =>
      props.hover === "false" &&
      css`
        &:focus,
        &:hover {
          color: ${(props) => props.color} !important;
          background-color: ${(props) => props.bgcolor} !important;
          outline: none;
          border-color: transparent !important;
        }
      `}
    &:focus{
        color: ${(props) => props.theme.colors.textColor} !important;
        ::placeholder,
        ::-webkit-input-placeholder {
            color: ${(props) => props.theme.colors.textColor} !important;
        }
        :-ms-input-placeholder {
            color: ${(props) => props.theme.colors.textColor} !important;
        }
    }
    ${(props) =>
      props.hover === "2" &&
      css`
        &:hover,
        &:focus {
          color: #222 !important;
          border-color: transparent !important;
          background: #fff;
          box-shadow: 0 0 40px rgba(51, 51, 51, 0.1);
        }
      `}
    @media ${device.small}{
        min-height: 100px;
    }
`;

export const NextButton = styled(Button)`
  margin-top: 30px;
  margin-left: 20px;
  @media ${device.small} {
    margin-left: 10px;
  };
`;
