import React from "react";
import { Row, Col } from "../../../../components/ui/wrapper";
import { Error } from "../../../../components/ui/form";

const ErrorStepSection = ({
    field,
    errors,
    errorMessage,
    stepNumber,
    showErrors=true
}) => {

    return (
        <Row>
          <Col lg={8}>
            {showErrors && <Error>{errors[field] && errorMessage}</Error>}
            </Col>
          <Col lg={4} style={{
            fontSize: '10px',
            fontStyle: 'italic',
            fontWeight: 600,
            alignSelf: 'center',
            textAlign: 'end',
          }}>
              {
              `( Step ${stepNumber} / 9 )`
              }
            </Col>
        </Row>
    )
}

export {  ErrorStepSection }