import React, { Fragment } from "react";
import { useForm } from "react-hook-form";
import updateAction from "./updateAction";
import { Input, Error } from "../../../../components/ui/form";
import Button from "../../../../components/ui/button";
import { Title ,NextButton} from "./free-consultation-steps.style";
import { Col } from "../../../../components/ui/wrapper";
import { useStateMachine } from "little-state-machine";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { ErrorStepSection } from './ErrorStepsSection';

const StepTen = ({ count, setCount }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const { register, handleSubmit, formState: { errors } } = useForm({
    mode: "onBlur",
  });
  const { actions, state } = useStateMachine({updateAction});

  const onSubmit = async ( stepData ) => {
    if (!executeRecaptcha) {
      console.log("executeRecaptcha is null");
      return;
    }

    const recaptchaToken = await executeRecaptcha("freeconsultation");

    actions.updateAction(stepData);
    const {data, ...formStateData} = state;
    const formData = {...formStateData, recaptchaToken };
    const formDataStr = JSON.stringify(formData);
        
    fetch('/api/free-consultation', {
      method: 'post',
      headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
      },
      body: formDataStr
    }).then(res => {
        setCount(count + 1);
    }).catch((error) => {
        console.error('Error:', error);
        alert(error);
    });
  };

  const onPreviousState = () => {
    setCount(count - 1);
  };

  return (
    <Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Title>What is your phone number ?</Title>
        <Input
          type="number"
          {...register('phone', { required: "Phone number is required" })}
          placeholder="Your phone"
          defaultValue={state.phone} />
        <ErrorStepSection 
          field="phone" 
          errors={errors} 
          errorMessage={errors?.phone?.message}
          stepNumber={9}
        />            
        <Col lg={12}>
          <Button 
            mt="30px" 
            skin="dark"
            onClick={(e) => onPreviousState()}
          >
            Back
          </Button>
          <NextButton hover={3} skin='secondary'>
            Submit
          </NextButton>
        </Col>
      </form>
    </Fragment>
  );
};

export { StepTen };
