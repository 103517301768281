import React, { useEffect } from "react";
import updateAction from "./updateAction";
import { Title } from "./free-consultation-steps.style";
import { useStateMachine } from "little-state-machine";
import { ErrorStepSection } from './ErrorStepsSection';

const StepTwo = ({ count, setCount }) => {
    const { state } = useStateMachine({updateAction});

    useEffect(() => {
      const timer = setTimeout(() => {
        setCount(count + 1);
      }, 3000);
      return () => clearTimeout(timer);
    }, []);
    return (
    <>
      <Title>Nice to meet you {state.name} </Title>
      <ErrorStepSection stepNumber={2} showErrors={false}/>
    </>
    )
};

export {StepTwo}