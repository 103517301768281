import React, { Fragment } from "react";
import { useForm } from "react-hook-form";
import updateAction from "./updateAction";
import { Error } from "../../../../components/ui/form";
import Button from "../../../../components/ui/button";
import { Title, Rowwrap, ErrorWrap, ColWrap ,NextButton} from "./free-consultation-steps.style";
import { Col } from "../../../../components/ui/wrapper";
import { useStateMachine } from "little-state-machine";
import { ErrorStepSection } from './ErrorStepsSection';

const StepEight = ({ count, setCount }) => {
  const { register, handleSubmit, formState: { errors }, setError } = useForm();
  const { actions, state } = useStateMachine({updateAction});

  const onSubmit = data => {
    if (data.budget == "" || !data.budget) {
      setError("budget", {
        type: "notMatch", 
        message: "Please choose your budget"
      });
    } else {
      actions.updateAction(data);
      setCount(count + 1);
    }
  };

  const onPreviousState = () => {
    setCount(count - 1);
  };

  return (
    <Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Title>What is your budget range?</Title>
        <label>
          The budget range allows us to make a proposal tailored to your needs
          according to your budget.
        </label>
        <ColWrap>
        <Rowwrap>
          <input
            style={{ margin: "0px 25px 0px 25px" }}
            type="radio"
            {...register('budget')}
            id="<500"
            value="<500" />
          <label htmlFor="range">Less than $ 5,000</label>{" "}
        </Rowwrap>
        <Rowwrap>
          <input
            style={{ margin: "0px 25px 0px 25px" }}
            type="radio"
            {...register('budget')}
            id="5to10000"
            value="5to10000" />
          <label htmlFor="range">Between $ 5,000 and $ 10,000 </label>{" "}
        </Rowwrap>
        <Rowwrap>
          <input
            style={{ margin: "0px 25px 0px 25px" }}
            type="radio"
            {...register('budget')}
            id="10to20000"
            value="10to20000" />
          <label htmlFor="range">Between $ 10,000 and $ 20,000</label>{" "}
        </Rowwrap>
        <Rowwrap>
          <input
            style={{ margin: "0px 25px 0px 25px" }}
            type="radio"
            {...register('budget')}
            id="20to40000"
            value="20to40000" />
          <label htmlFor="range">Between $ 20,000 and $ 40,000 </label>{" "}
        </Rowwrap>
        <Rowwrap>
          <input
            style={{ margin: "0px 25px 0px 25px" }}
            type="radio"
            {...register('budget')}
            id=">40000"
            value=">40000" />
          <label htmlFor="range">$ 40,000 or more </label>{" "}
        </Rowwrap>
        <Rowwrap>
          <input
            style={{ margin: "0px 25px 0px 25px" }}
            type="radio"
            {...register('budget')}
            id="tbd"
            value="tbd" />
          <label htmlFor="range">Yet to define</label>{" "}
        </Rowwrap>
        </ColWrap>
        
        <ErrorStepSection 
            field="budget" 
            errors={errors} 
            errorMessage={errors?.budget?.message}
            stepNumber={7}
        /> 
        <Col lg={12}>
          <Button 
            mt="30px" 
            skin="dark"
            onClick={e => onPreviousState()}
          >
            Back
          </Button>
          <NextButton hover={3} skin='secondary'>
            Next
          </NextButton>
        </Col>
      </form>
    </Fragment>
  );
};

export { StepEight };
