import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { Container, Row, Col } from "../../components/ui/wrapper";
import Text from "../../components/ui/text";
import {
  SectionWrap,
  FreeConsultationHeading,
  FreeConsultationTagline,
  FreeConsultationText,
  ItemsUl,
  ItemsLi,
} from "./free-consultation-description.style";
import parse from "html-react-parser";

const FreeConsultationDescription = (props) => {
  const DescriptionData = useStaticQuery(graphql`
    query DescriptionDataQuery {
      freeConsultationJson {
        id
        title
        excerpt
        tagline
        items {
          text
        }
      }
    }
  `);
  const {
    title,
    excerpt,
    tagline,
    items,
  } = DescriptionData.freeConsultationJson;
  return (
    <SectionWrap>
      <Container>
        <Row alignitems="center">
          <Col lg={12}>
            <FreeConsultationHeading>{parse(title)}</FreeConsultationHeading>
            <FreeConsultationText>{excerpt}</FreeConsultationText>
            <FreeConsultationTagline>{parse(tagline)}</FreeConsultationTagline>
            <ItemsUl>
              {items.map((item, i) => {
                return <ItemsLi key={i}>{item.text}</ItemsLi>;
              })}
            </ItemsUl>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

export { FreeConsultationDescription };
