import React from "react";
import { StateMachineProvider } from "little-state-machine";
import { FormGroup } from "../../../components/ui/form";
import { Container, Row, Col } from "../../../components/ui/wrapper";

import { StepsManager } from "./StepsManager";
import { FirstPageFormWrapper, LeftBox } from "./free-consultation-form.style";
import AppReCaptchaProvider from '../../../components/recaptcha';

const FreeConsultationForm = (props) => {
  return (
    <StateMachineProvider>
      <FirstPageFormWrapper>
        <Container>
          <Row alignitems="center">
            <Col lg={12}>
              {/* <LeftBox>
                <Col lg={8}> */}
                  <FormGroup mb="20px">
                    <AppReCaptchaProvider>
                      <StepsManager />
                    </AppReCaptchaProvider>
                  </FormGroup>
                {/* </Col>
              </LeftBox> */}
            </Col>
          </Row>
        </Container>
      </FirstPageFormWrapper>
    </StateMachineProvider>
  );
};

export { FreeConsultationForm };
