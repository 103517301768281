import React from "react";
import SEO from "../components/seo";
import Layout from "../containers/layout/layout";
import Header from "../containers/layout/header/header-one";
import Footer from "../containers/layout/footer/footer-one";
import PageHeader from "../components/pageheader";
import { Container, Row, Col } from "../components/ui/wrapper";
import CTAArea from "../containers/global/cta-area/section-one";
import { FreeConsultationForm } from "../components/forms/free-consultation-form/index.js";
import { createStore } from "little-state-machine";
import { FreeConsultationDescription } from "../containers/free-consultation-description";

createStore({
  data: {},
});
//window.STATE_MACHINE_RESET();

const seo = {
  title: "Get a free consultation",
  description: "Kickstart your project with a free 30 minutes consultation with our technology experts",
  image: "/dark-logo.png",
  pathname: "/free-consultation",
};


const FreeConsultationPage = ({ pageContext, location }) => (
  <Layout location={location}>
    <SEO
      title={seo.title}
      description={seo.description}
      image={`${seo.image}`}
      pathname={seo.pathname}
    />   
    <Header />
    <PageHeader
      pageContext={pageContext}
      location={location}
      title="Get a free consultation"
    />
    <main className="site-wrapper-reveal">
      <Container>
        <Row alignitems="center">
          <Col lg={6}>
            <FreeConsultationDescription />
          </Col>
          <Col lg={6}>
            <FreeConsultationForm />
          </Col>
        </Row>
      </Container>
      <CTAArea />
    </main>
    <Footer />
  </Layout>
);

export default FreeConsultationPage;
