import styled from "styled-components";
import { device } from "../../../theme";

export const FirstPageFormWrapper = styled.section`
  padding-top: 100px;
  padding-bottom: 64px;
  @media ${device.medium} {
    padding-top: 15px;
    padding-bottom: 45px;
  }
  @media ${device.small} {
    padding-top: 10px;
    padding-bottom: 45px;
  }
`;

export const LeftBox = styled.div`
  @media ${device.medium} {
    margin-bottom: 45px;
  }
  text-align: center;
  justify-content: center;
  display: flex;
`;

export const Title = styled.h5`
  font-weight: 400;
  line-height: 1.3;
  color: rgb(68, 68, 67);
  margin-bottom: 16px;
  font-size: 2.25rem;
`;
